<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12" md="9" sm="9">
        <v-row>
          <v-col md="4" offset-md="1">
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader class="custom-skeleton" type="image">
              </v-skeleton-loader>
            </template>
            <v-sheet
              v-else
              class="width-100 text-right"
              elevation="15"
              height="100"
              width="100"
              :style="{
                'background-color': activeColor.hexa,
                height: '100%',
                'border-radius': '10px',
              }"
            ></v-sheet>
          </v-col>
          <v-col md="4" offset-md="1">
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader class="custom-skeleton" type="image">
              </v-skeleton-loader>
            </template>
            <v-color-picker
              v-else
              class="ma-2"
              elevation="15"
              v-model.trim="activeColor"
              :swatches="swatches"
              show-swatches
            ></v-color-picker>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="custom-sidebar-container grey lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { EngineerEventBus } from "@/core/lib/engineer/engineer.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  name: "address",
  mixins: [ValidationMixin, CommonMixin],
  props: {
    pageLoading: {
      type: Boolean,
      default: false,
    },
    updateEngineer: {
      type: Boolean,
      default: false,
    },
    engineer: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      activeColor: {
        alpha: 1,
        hex: "#00BCD4",
        hexa: "#00BCD4FF",
        hsla: {
          a: 1,
          h: 186.79245283018867,
          l: 0.41568627450980394,
          s: 1,
        },
        hsva: {
          a: 1,
          h: 186.79245283018867,
          s: 1,
          v: 0.8313725490196079,
        },
        hue: 186.79245283018867,
        rgba: {
          a: 1,
          b: 212,
          g: 188,
          r: 0,
        },
      },
      fieldDescriptions: [],
    };
  },
  watch: {
    activeColor: {
      deep: true,
      immediate: true,
      handler(param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          EngineerEventBus.$emit("update:color", param);
        }, _this.timeoutLimit);
      },
    },
    engineer() {
      this.setColor();
    },
  },
  methods: {
    setColor() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineer) === false) {
        _this.activeColor.hexa = _this.engineer.color;
      }
    },
  },
  mounted() {
    this.setColor();
  },
};
</script>
